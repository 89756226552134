import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import ListItem from "../List/ListItem";

const Item = ({ children, onToggle, icon, onClick, className, ...props }) => (
  <ListItem
    as="li"
    icon={icon}
    className={twMerge(
      "w-full flex items-center text-gray-700 text-sm px-4 py-2.5 hover:bg-gray-50",
      className,
    )}
    {...props}
    {...(onClick ? { onClick: () => onClick(onToggle) } : {})}
  >
    {children}
  </ListItem>
);

Item.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onToggle: PropTypes.func,
  className: PropTypes.string,
};

export default Item;
