import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import ChevronDown from "@sholdi/icons/ChevronDown";
import Button from "@sholdi/primitives/atoms/Button";

const DropdownButton = forwardRef(
  (
    {
      label = "Options",
      showOnlyIcon,
      hideIcon,
      icon: Icon = ChevronDown,
      iconSizes = { width: 5, height: 5 },
      className,
      ...props
    },
    ref,
  ) => (
    <Button
      {...props}
      {...(showOnlyIcon ? { "aria-label": "dropdown-button" } : {})}
      icon={!hideIcon && Icon && Icon}
      iconProps={{
        className: "mr-0",
        ...iconSizes,
      }}
      ref={ref}
      className={twMerge("flex items-center font-medium", className)}
    >
      {!showOnlyIcon && label && label}
    </Button>
  ),
);

DropdownButton.displayName = "DropdownButton";

DropdownButton.propTypes = {
  label: PropTypes.string,
  showOnlyIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  iconSizes: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  hideIcon: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default DropdownButton;
