/* eslint-disable react/prop-types */
import {
  useState,
  forwardRef,
  Children,
  isValidElement,
  cloneElement,
} from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { twMerge } from "tailwind-merge";

import Divider from "@sholdi/primitives/atoms/Divider";

import DropdownMenu from "./DropdownMenu";

import Item from "./Item";

import DropdownButton from "./DropdownButton";
import ClickOutside from "../../lib/ClickOutside";

const Dropdown = forwardRef(
  (
    {
      label = "Options",
      activatorButton: Activator = DropdownButton,
      activatorProps = {},
      showOnlyIcon = false,
      icon,
      iconSizes,
      id,
      disabled,
      variant = "none",
      children,
      divided = false,
      alignment = "right",
      menuProps,
      hideIcon,
      className,
      ...props
    },
    ref,
  ) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const onClick = () => setMenuVisible((prev) => !prev);

    const buttonProps = {
      disabled,
      onClick: () => {
        if (disabled) return;
        onClick();
      },
      role: "button",
      type: "button",
      "aria-haspopup": "true",
      "aria-expanded": menuVisible,
      id,
      // if you pass it below, it causes hydration errors
      // with this, just make sure it's props are not passed where the component is used
      ...(iconSizes ? { iconSizes } : {}),
      ...(hideIcon ? { hideIcon } : {}),
      ...(icon ? { icon } : {}),
      ...(showOnlyIcon ? { showOnlyIcon } : {}),
      ...(label ? { label } : {}),
      ...activatorProps,
    };

    return (
      <ClickOutside active={menuVisible} onClick={onClick}>
        <div
          ref={ref}
          className={twMerge("relative w-fit shadow-0", className)}
          {...props}
        >
          <Activator {...buttonProps} />
          <CSSTransition
            in={menuVisible}
            timeout={100}
            classNames="menu"
            unmountOnExit
          >
            <DropdownMenu
              alignment={alignment}
              {...menuProps}
              as="ul"
              role="menu"
              className={twMerge("min-w-36", menuProps?.className)}
            >
              {Children.map(children, (child, index) => (
                <>
                  {child &&
                    isValidElement(child) &&
                    cloneElement(child, {
                      role: "menuitem",
                      tabIndex: 0,
                      variant,
                      themeKey: "lists",
                      childKey: "listItem",
                      onToggle: onClick,
                    })}
                  {divided && child
                    ? index !== children.length - 1 && <Divider my={0} />
                    : null}
                </>
              ))}
            </DropdownMenu>
          </CSSTransition>
        </div>
      </ClickOutside>
    );
  },
);

Dropdown.Item = Item;

Dropdown.propTypes = {
  activatorButton: PropTypes.elementType,
  activatorProps: PropTypes.shape({}),
  iconSizes: PropTypes.shape({}),
  showOnlyIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["none"]),
  divided: PropTypes.bool,
  alignment: PropTypes.oneOf(["left", "right"]),
  hideIcon: PropTypes.bool,
  closeAfterClick: PropTypes.bool,
  className: PropTypes.string,
};

Dropdown.displayName = "Dropdown";

export default Dropdown;
