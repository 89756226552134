import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import { ListItemBase, ListItemContainer, ListItemContent } from "./components";

const ListItem = forwardRef(
  ({ left, right, children, variant, className, expanded, ...props }, ref) => (
    <ListItemBase
      ref={ref}
      variant={variant}
      className={twMerge(
        "font-heading font-normal text-base leading-6 py-2.5 px-4 gap-3.5",
        className,
      )}
      {...props}
    >
      <ListItemContainer
        variant={variant}
        // className={className}
        className="gap-3.5"
        expanded={expanded}
      >
        {left && left}
        <ListItemContent
          // className={className}
          variant={variant}
          className="w-full items-center"
          expanded={expanded}
        >
          {children}
        </ListItemContent>
      </ListItemContainer>
      {right && right}
    </ListItemBase>
  ),
);

ListItem.defaultProps = {
  variant: "none",
};

ListItem.propTypes = {
  children: PropTypes.node,
  left: PropTypes.node,
  right: PropTypes.node,
  variant: PropTypes.string,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};

ListItem.displayName = "ListItem";

export default ListItem;
