import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const ChevronDown = ({ className, fill, ...props }) => (
  <SVG
    aria-label="ChevronDown"
    viewBox="0 0 24 10"
    className={twMerge("w-3 h-1 fill-transparent", className)}
    {...props}
  >
    <path
      d="M1 1.99121L7 7.99121L13 1.99121"
      stroke="currentColor"
      strokeWidth="2"
      fill={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

ChevronDown.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ChevronDown;
