import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Divider = ({ className, ...props }) => (
  <div
    as="hr"
    className={twMerge("bg-gray-100 my-2 mx-0 h-px border-0", className)}
    {...props}
  />
);

Divider.displayName = "Divider";

Divider.propTypes = {
  className: PropTypes.string,
};

export default Divider;
