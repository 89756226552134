import PropTypes from "prop-types";
import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import getVariantChildStyles from "@sholdi/shared/helpers/getVariantChildStyles";
import Box from "@sholdi/primitives/atoms/Box";

const variants = {
  shareDropdown: {
    listItem:
      "text-gray-700 py-2 px-4 border border-gray-300 rounded w-auto opacity-100 bg-white justify-center items-center cursor-pointer",
  },
  none: {},
  adminSidebar: {
    listItem: "py-2 px-4 mb-4",
    listItemContainer: "justify-between gap-0",
    listItemContent: "hidden",
  },
  default: {
    listItemSectionWrapper:
      "w-full items-center justify-between mt-3 pt-3 px-2",
    listItemSectionTitle: "font-bold text-gray-50 uppercase",
    listItemSectionIcon: "",
  },
  blank: {
    listItem:
      "pl-0 bg-transparent text-secondary-main cursor-default font-regular hover:text-primary-main",
  },
  footer: {
    listItem:
      "pl-0 bg-transparent text-secondary-main cursor-default font-regular hover:text-primary-main",
  },
  userProfile: {
    listItem: "p-4 font-heading",
  },
};

const expandedClasses = {
  adminSidebar: {
    listItemContainer: "justify-start",
    listItemContent: "block",
  },
};

const itemBase = cva("flex items-center justify-between cursor-pointer", {
  variants: {
    variant: getVariantChildStyles(variants, "listItem"),
  },
});

export const ListItemBase = ({ variant, className, ...props }) => (
  <Box
    as="li"
    className={twMerge(itemBase({ variant }), className)}
    {...props}
  />
);

ListItemBase.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const container = cva("flex items-center w-full", {
  variants: {
    variant: getVariantChildStyles(variants, "listItemContainer"),
  },
});

export const ListItemContainer = ({
  variant,
  className,
  expanded,
  ...props
}) => (
  <div
    className={twMerge(
      container({ variant }),
      expanded ? expandedClasses[variant]?.listItemContainer : "",
      className,
    )}
    {...props}
  />
);

ListItemContainer.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};

const content = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "listItemContent"),
  },
});

export const ListItemContent = ({ variant, className, expanded, ...props }) => (
  <div
    className={twMerge(
      content({ variant }),
      expanded ? expandedClasses[variant]?.listItemContent : "",
      className,
    )}
    {...props}
  />
);

ListItemContent.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};
