const variants = {
  none: "",
  blank: "bg-transparent w-auto px-0 hover:bg-transparent hover:underline",
  transparent: "bg-transparent",
  primary:
    "text-white font-medium bg-primary-main hover:bg-primary-dark disabled:bg-primary-lighter focus:border-primary-main focus:shadow-[0_0_0_4px_primary-lighter]",
  primaryTransparent:
    "text-primary-main border border-primary-main font-medium bg-transparent hover:bg-primary-main hover:text-white hover:border-white disabled:hover:bg-primary-lighter focus:shadow-[0_0_0_4px_primary-lighter] focus:border-primary-main",
  secondary:
    "bg-secondary-main text-white hover:bg-secondary-dark disabled:bg-secondary-light disabled:hover:bg-secondary-light focus:border-secondary-main focus:shadow-[0_0_0_4px_secondary-lighter]",
  gray: "bg-gray-50 text-white hover:bg-gray-dark disabled:bg-gray-light disabled:hover:bg-gray-light focus:border-gray-main focus:shadow-[0_0_0_4px_gray-lighter]",
  light:
    "bg-primary-lighter text-primary-dark hover:bg-primary-dark hover:text-white disabled:bg-primary-light disabled:hover:bg-primary-light focus:border-primary-main focus:shadow-[0_0_0_4px_primary-lighter]",
  error:
    "bg-error-main text-white hover:bg-error-dark disabled:bg-error-light disabled:hover:bg-error-light focus:border-error-main focus:shadow-[0_0_0_4px_error-lighter]",
  success:
    "bg-success-main text-white hover:bg-success-dark disabled:bg-success-light disabled:hover:bg-success-light focus:border-success-main focus:shadow-[0_0_0_4px_success-lighter]",
  whiteGray:
    "bg-white text-gray-700 font-medium border border-gray-300 hover:bg-gray-50 hover:text-gray-800 disabled:text-gray-300 disabled:hover:bg-white focus:shadow-[0_0_0_4px_gray-lighter] focus:border-gray-300",
  outline:
    "bg-transparent border-2 border-white text-white hover:bg-primary-main hover:disabled:text-gray-300 hover:disabled:bg-gray-500 focus:border-primary-main focus:shadow-[0_0_0_4px_primary-lighter]",
  outlinePrimary:
    "bg-transparent text-primary-main border-2 border-primary-main hover:bg-primary-main hover:text-white hover:border-primary-main",
  outlineError:
    "bg-transparent text-error-main border border-error-main hover:bg-error-main hover:text-white hover:border-error-main",
  tableAction: "bg-transparent text-primary-main border-0 font-normal",
};

export default variants;
